<template>
    <b-list-group-item class="quotidien-row">
        <b-row class="mb-0">
            <!-- parution -->
            <div class="col-sm-12 col-md-2 mb-2 mb-md-0 text-capitalize" style="padding-top: 0.4em">
                {{ moment(this.numero, 'YYYY-MM-DD').format('dddd D MMMM') }}
                <b-button v-if="moment(this.numero, 'YYYY-MM-DD').isFerie()" id="button-2" variant="link"
                    ><i class="fa fa-question-circle"></i
                ></b-button>
                <b-tooltip v-if="moment(this.numero, 'YYYY-MM-DD').isFerie()" target="button-2" placement="right">
                    Il s'agit d'un jour férié. Vous ne pouvez pas avoir reçu votre quotidien par la Poste ce jour là. En
                    cas de problème, merci de contacter la hotline.
                </b-tooltip>
           </div>
            <!-- recu -->
            <div class="col-sm-12 col-md-3 mb-2 mb-md-0">
                <b-form-select
                    v-model="selected"
                    :disabled="moment(numero, 'YYYY-MM-DD').isAfter(moment())"
                    :options="optionIsToDay"
                ></b-form-select>
            </div>
            <!-- date de reception -->
            <div class="col-sm-12 col-md-2">
                <Datepicker
                    v-if="selected && (selected === 'avance' || selected === 'retard')"
                    id="dateReception"
                    v-model="dateReception"
                    :disabled="moment(numero, 'YYYY-MM-DD').isAfter(moment())"
                    format="dd/MM/yyyy"
                    :monday-first="true"
                    :language="fr"
                    :disabled-dates="{
                        to:
                            selected === 'retard'
                                ? moment(numero, 'YYYY-MM-DD').add(1, 'day').toDate()
                                : minDateDeclaAvance,
                        from: selected === 'retard' ? new Date() : moment(numero, 'YYYY-MM-DD').toDate(),
                        days: [0]
                    }"
                    placeholder="Date de réception"
                    :bootstrap-styling="true"
                    input-class="datepicker"
                />
            </div>
            <!-- Date imprimée -->
            <div v-if="titreId === '72' || titreId === '4804'" class="col-sm-12 col-md-2">
                <Datepicker
                    v-if="
                        !noDateImprimee &&
                        (selected === 'retard' ||
                            selected === 'jour' ||
                            selected === 'avance' ||
                            selected === 'incertain')
                    "
                    id="dateImprimee"
                    v-model="dateImprimee"
                    :disabled="moment(numero, 'YYYY-MM-DD').isAfter(moment())"
                    format="dd/MM/yyyy"
                    :monday-first="true"
                    :language="fr"
                    :disabled-dates="{ to: minDateImpr, from: maxDateImpr, days: [0] }"
                    placeholder="Date imprimée"
                    :bootstrap-styling="true"
                    input-class="datepicker"
                />
            </div>
            <!-- Date imprimée illisible -->
            <div v-if="titreId === '72' || titreId === '4804'" class="col-sm-12 col-md-2">
                <b-form-checkbox
                    v-if="
                        selected === 'retard' ||
                        selected === 'jour' ||
                        selected === 'avance' ||
                        selected === 'incertain'
                    "
                    v-model="noDateImprimee"
                    :disabled="moment(numero, 'YYYY-MM-DD').isAfter(moment())"
                    class="mt-2"
                >
                    <span class="d-block d-md-none">Date imprimée illisible/absente</span>
                </b-form-checkbox>
            </div>
            <!-- Distribué par La Poste -->
            <div class="col-sm-12 col-md-1">
                <b-form-group
                    v-if="
                        selected &&
                        (selected === 'avance' ||
                            selected === 'incertain' ||
                            selected === 'jour' ||
                            selected === 'retard')
                    "
                >
                <span class="d-block d-md-none"><br/>Distribué par La Poste</span>
                    <b-form-radio
                        v-model="distribue_laposte"
                        value="true"
                        style="display: inline-block; margin-right: 1em"
                    >
                        Oui
                    </b-form-radio>
                    <b-form-radio
                        v-model="distribue_laposte"
                        value="false"
                        style="display: inline-block; margin-right: 1em"
                    >
                        Non
                    </b-form-radio>
                </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-md-6 col-sm-12">
                <b-form-checkbox v-if="distribue_laposte === 'false'" v-model="distributionConfirmation">
                    Confirmez-vous que cet exemplaire n’a pas été distribué par La Poste ?
                </b-form-checkbox>
            </div>
        </b-row>
    </b-list-group-item>
</template>

<script>
import Vue from '../main'
import Datepicker from 'vuejs-datepicker'
import fr from 'vuejs-datepicker/dist/locale/translations/fr'
import moment from 'moment'

export default {
    components: {
        Datepicker
    },
    props: ['declaration', 'hide'],
    data() {
        return {
            titreId: null,
            options: [
                { value: null, text: ' -- Sélectionner --' },
                { value: 'jour', text: 'Reçu le jour même' },
                { value: 'retard', text: 'Reçu en retard' },
                { label: ' ', options: [] },
                { value: 'pas_encore', text: 'Pas encore/jamais reçu' },
                { value: 'incertain', text: 'Reçu à une date incertaine' }
            ],
            minDateDeclaAvance: null,
            minDateImpr: null,
            maxDateImpr: null,
            selected: this.declaration.statut_quotidien ? this.declaration.statut_quotidien : null,
            dateReception: this.declaration.date_reception
                ? moment(this.declaration.date_reception, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : null,
            dateImprimee: this.declaration.date_imprimee
                ? moment(this.declaration.date_imprimee, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : null,
            noDateImprimee: this.declaration.date_imprimee_incertaine,
            // distribue_laposte: this.declaration.distribue_laposte == true ? "1" : this.declaration.distribue_laposte == false ? "0" : null,
            distribue_laposte: this.declaration.statut_quotidien == "pas_encore" ? null : this.declaration.distribue_laposte,
            distributionConfirmation: false,
            numero: this.declaration.numero,
            moment,
            fr
        }
    },
    beforeMount() {
        this.titreId = this.$route.params.titreId

        if (this.titreId === '72' || this.titreId === '4804') {
            this.options.shift()
            this.options.unshift(
                { value: null, text: ' -- Sélectionner --' },
                { value: 'avance', text: 'Reçu en avance' }
            )
        }
    },
    mounted() {
        //reset le radio button si pas_encore est changé en un autre statut pour obliger la sélection de distribue_laposte
        this.declaration.distribue_laposte = this.declaration.statut_quotidien == "pas_encore" ? null : this.declaration.distribue_laposte

        const weekDayRaw = moment(this.numero, 'YYYY-MM-DD')
        const ddd = moment(weekDayRaw).format('d')

        if (ddd === '1') this.minDateDeclaAvance = moment(weekDayRaw).subtract(3, 'day').toDate()
        else if (ddd === '2') this.minDateDeclaAvance = moment(weekDayRaw).subtract(4, 'day').toDate()
        else if (ddd === '3' || ddd === '5') this.minDateDeclaAvance = moment(weekDayRaw).subtract(1, 'day').toDate()
        else this.minDateDeclaAvance = moment(weekDayRaw).subtract(2, 'day').toDate()

        //_JPM_2024-11-17_cas_specifique_lundi_11_novembre_2024_férie pour Le Petit Quotidien et Mon Quotidien :
        if ((this.titreId === '72' || this.titreId === '4804') && moment(this.numero, 'YYYY-MM-DD').format('YYYY-MM-DD') === "2024-11-13") this.minDateDeclaAvance = moment(weekDayRaw).subtract(5, 'day').toDate()

        if (ddd === '1') this.minDateImpr = moment(weekDayRaw).subtract(4, 'day').toDate()
        else if (ddd === '2') this.minDateImpr = moment(weekDayRaw).subtract(5, 'day').toDate()
        else if (ddd === '3' || ddd === '5') this.minDateImpr = moment(weekDayRaw).subtract(2, 'day').toDate()
        else this.minDateImpr = moment(weekDayRaw).subtract(3, 'day').toDate()

        //_JPM_2024-11-17_cas_specifique_lundi_11_novembre_2024_férie pour Le Petit Quotidien et Mon Quotidien :
        if ((this.titreId === '72' || this.titreId === '4804') && moment(this.numero, 'YYYY-MM-DD').format('YYYY-MM-DD') === "2024-11-13") this.minDateImpr = moment(weekDayRaw).subtract(6, 'day').toDate()
        //_JPM_2024-12-27_cas_specifique_mercredi_25_décembre_férie pour Le Petit Quotidien et Mon Quotidien :
        if ((this.titreId === '72' || this.titreId === '4804') && moment(this.numero, 'YYYY-MM-DD').format('YYYY-MM-DD') === "2024-12-27") this.minDateImpr = moment(weekDayRaw).subtract(3, 'day').toDate()
        if ((this.titreId === '72' || this.titreId === '4804') && moment(this.numero, 'YYYY-MM-DD').format('YYYY-MM-DD') === "2024-12-28") this.minDateImpr = moment(weekDayRaw).subtract(4, 'day').toDate()
        //_JPM_2024-01-03_cas_specifique_mercredi_1_janvier pour Le Petit Quotidien et Mon Quotidien :
        if ((this.titreId === '72' || this.titreId === '4804') && moment(this.numero, 'YYYY-MM-DD').format('YYYY-MM-DD') === "2024-12-25") this.minDateImpr = moment(weekDayRaw).subtract(5, 'day').toDate()
        if ((this.titreId === '72' || this.titreId === '4804') && moment(this.numero, 'YYYY-MM-DD').format('YYYY-MM-DD') === "2025-01-01") this.minDateImpr = moment(weekDayRaw).subtract(5, 'day').toDate()
        if ((this.titreId === '72' || this.titreId === '4804') && moment(this.numero, 'YYYY-MM-DD').format('YYYY-MM-DD') === "2025-01-03") this.minDateImpr = moment(weekDayRaw).subtract(3, 'day').toDate()
        if ((this.titreId === '72' || this.titreId === '4804') && moment(this.numero, 'YYYY-MM-DD').format('YYYY-MM-DD') === "2025-01-04") this.minDateImpr = moment(weekDayRaw).subtract(4, 'day').toDate()

        this.maxDateImpr =
            ddd === '6' ? moment(weekDayRaw).add(3, 'day').toDate() : moment(weekDayRaw).add(2, 'day').toDate()
    },
    created() {
        Vue.$on('confort', () => {
            if (!moment(this.numero, 'YYYY-MM-DD').isAfter(moment())) this.selected = 'jour'
        })
    },
    computed: {
        optionIsToDay() {
            if (this.numero === moment().format('YYYY-MM-DD')) return this.options.filter(e => e.value !== 'retard')

            return this.options
        }
    },
    watch: {
        selected: function (val, old) {
            if (val !== old) {
                this.$emit('onRowChanged', {
                    numero: this.numero,
                    value: {
                        recu: val
                    }
                })
                if (val === 'jour') {
                    this.$emit('onRowChanged', {
                        numero: this.numero,
                        value: {
                            dateReception: moment(this.numero, 'YYYY-MM-DD').toDate()
                        }
                    })
                } else this.dateReception = null
            }
        },
        dateReception: function (val, old) {
            if (val !== old)
                this.$emit('onRowChanged', {
                    numero: this.numero,
                    value: {
                        dateReception: val
                    }
                })
        },
        dateImprimee: function (val, old) {
            if (val !== old)
                this.$emit('onRowChanged', {
                    numero: this.numero,
                    value: {
                        dateImprimee: val
                    }
                })
        },
        noDateImprimee: function (val, old) {
            if (val !== old) {
                this.$emit('onRowChanged', {
                    numero: this.numero,
                    value: {
                        noDateImprimee: val
                    }
                })
                if (!val) this.dateImprimee = null
            }
        },
        distribue_laposte: function (val, old) {
            if (val !== old) {
                this.$emit('onRowChanged', {
                    numero: this.numero,
                    value: {
                        distribue_laposte: val
                    }
                })
                if (!val) this.distribue_laposte = null
            }
        },
        distributionConfirmation: function (val, old) {
            if (val !== old) {
                this.$emit('onRowChanged', {
                    numero: this.numero,
                    value: {
                        distributionConfirmation: val
                    }
                })
                if (!val) this.distributionConfirmation = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '../assets/scss/bootstrap';

.quotidien-row {
    padding-bottom: 3px !important;

    .vdp-datepicker {
        max-width: 185px;

        .datepicker {
            background: transparent;
            border-radius: $border-radius !important;
            padding-left: 1em;

            &[disabled='disabled'] {
                background-color: #e9ecef;
            }
        }

        .vdp-datepicker__calendar {
            z-index: 101;
        }
    }
}
</style>
